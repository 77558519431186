export enum SupplierRatingEnum {
  NOT_RATED = "NOT_IDENTIFIED",
  HIGH_RISK = "HIGH_RISK",
  MODERATE_RISK = "MODERATE_RISK",
  TOLERABLE = "TOLERABLE",
}

export enum SupplierInfoNumberEnum {
  HIGH,
  MEDIUM,
  LOW,
}

export enum ExtraFieldTypeEnum {
  TEXT = "TEXT",
  CHECKBOXES = "CHECKBOXES",
}
