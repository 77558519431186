import { ExtraFieldCheckboxValueModel } from "@/modules/client/models/suppliers/extra-fields/extra-field-checkbox-value.model";
import { ExtraFieldTypeEnum } from "@/core/enums/suppliers";
import { SupplierExtraFieldType } from "@/core/types/suppliers";

export class SupplierExtraFieldModel {
  type: ExtraFieldTypeEnum;
  label: string;
  value: string | number[] | null;
  values: Array<ExtraFieldCheckboxValueModel>;

  get isText(): boolean {
    return this.type === ExtraFieldTypeEnum.TEXT;
  }

  get isCheckbox(): boolean {
    return this.type === ExtraFieldTypeEnum.CHECKBOXES;
  }

  get apiData(): SupplierExtraFieldType {
    let value = this.value;
    if (Array.isArray(value) && !this.isCheckbox) value = "";
    if (Array.isArray(value) && this.isCheckbox) {
      value = (value || []).filter((e) => {
        return this.values.find((v) => v.id === e);
      });
    }
    return {
      type: this.type,
      label: this.label.trim(),
      value,
      values: this.isCheckbox ? this.values.map((e) => e.apiData) : [],
    };
  }

  constructor(data?: SupplierExtraFieldType) {
    if (!data) {
      this.type = ExtraFieldTypeEnum.TEXT;
      this.label = "";
      this.value = null;
      this.values = [];
      return;
    }
    this.type = data.type;
    this.label = data.label;
    this.value = data.value;
    this.values = (data.values || []).map(
      (e) => new ExtraFieldCheckboxValueModel(e)
    );
  }

  clone(): SupplierExtraFieldModel {
    return new SupplierExtraFieldModel(this.apiData);
  }

  addEmptyValue(): void {
    if (!this.isCheckbox)
      throw new Error("Only checkbox fields can have values");
    const lastValue = this.values[this.values.length - 1];
    const newId = lastValue ? lastValue.id + 1 : 1;
    this.values.push(
      new ExtraFieldCheckboxValueModel({ id: newId, label: "" })
    );
  }

  removeValue(id: number): void {
    if (!this.isCheckbox)
      throw new Error("Only checkbox fields can have values");
    this.values = this.values.filter((e) => e.id !== id);
  }

  isChecked(checkboxValue: ExtraFieldCheckboxValueModel): boolean {
    if (!this.isCheckbox)
      throw new Error("Only checkbox fields can have values");
    return !!this.value && (this.value as number[]).includes(checkboxValue.id);
  }

  toggleCheckbox(checkboxValue: ExtraFieldCheckboxValueModel): void {
    if (!this.isCheckbox)
      throw new Error("Only checkbox fields can have values");
    const value = (this.value as number[]) || [];
    if (this.isChecked(checkboxValue)) {
      this.value = value.filter((e) => e !== checkboxValue.id);
    } else {
      this.value = [...value, checkboxValue.id];
    }
  }
}
