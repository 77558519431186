export class ExtraFieldCheckboxValueModel {
  id: number;
  label: string;

  get apiData(): { id: number; label: string } {
    return {
      id: this.id,
      label: (this.label || "").trim(),
    };
  }

  constructor(data: { id: number; label: string }) {
    this.id = data.id;
    this.label = data.label;
  }
}
